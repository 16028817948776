const MENUS = {
  // ["dashboard","online-order","store-order","products","profile","promo-codes","add-account","availability","manage-category","subuser","report","accounts"]
  "online-order": {
    name: "Online Order",
    slug: "online-order/list",
    icon: "zmdi-shopping-cart",
    sort: 2,
    subMenus: [],
  },
  // "store-order": {
  //   name: "In-Store Order",
  //   slug: "store-order/storelist",
  //   icon: "zmdi-collection-item zmdi-hc-fw",
  //   sort: 3,
  //   subMenus: [],
  // },
  // drivers: {
  //   name: "Driver",
  //   slug: "drivers/list",
  //   icon: "zmdi zmdi-car",
  //   sort: 4,
  //   subMenus: [],
  // },

  products: {
    name: "Products",
    slug: "products",
    icon: "zmdi zmdi-mall",
    sort: 5,
    subMenus: [
      {
        name: "All",
        slug: "products/list",
        icon: "zmdi zmdi-view-list",
      },
      {
        name: "Add",
        slug: "products/add",
        icon: "zmdi zmdi-plus-circle",
      },
      {
        name: "CSV import",
        slug: "products/import",
        icon: "zmdi zmdi-cloud-upload",
      },
    ],
  },
  // "promo-codes": {
  //   name: "Promo Code",
  //   slug: "promo-codes",
  //   icon: "zmdi-view-dashboard zmdi-hc-fw",
  //   sort: 4,
  //   subMenus: [],
  // },

  settings: {
    name: "Settings",
    slug: "settings",
    icon: "zmdi-settings",
    sort: 5,
    subMenus: [
      {
        name: "Profile",
        slug: "profile",
        icon: "zmdi-account-circle",
        keyName: "profile",
      },
      {
        name: "Add Bank Account",
        slug: "add-account/add",
        icon: "zmdi zmdi-case-check",
        keyName: "add-account",
      },
      {
        name: "Set Availability",
        slug: "availability/list",
        icon: "zmdi-time",
        keyName: "availability",
      },
    ],
  },
  managecategory: {
    name: "Manage Category",
    slug: "manage-category",
    icon: "zmdi-case-check",
    sort: 6,
    subMenus: [],
  },
  subuser: {
    name: "Panel User",
    slug: "subuser",
    icon: "zmdi-view-dashboard zmdi-hc-fw",
    sort: 7,
    subMenus: [],
  },
  report: {
    name: "Report",
    slug: "report",
    icon: "zmdi-comment-alt-text",
    sort: 8,
    subMenus: [],
  },
  payout: {
    name: "Payout",
    slug: "accounts",
    icon: "zmdi-assignment-account",
    sort: 9,
    subMenus: [],
  },
  adminOrder: {
    name: "Admin Order",
    slug: "adminOrder",
    icon: "zmdi-shopping-cart",
    sort: 3,
    subMenus: [],
  },
};

const menu= {...MENUS}
delete menu.settings
delete menu.subuser


const MenusList = [...Object.keys(menu),"profile","availability","add-account" ].map((key, i) => {
  return { [key]: MENUS[key] && MENUS[key].name || key };
});

const Menu = Object.keys(MENUS);
const MenusKeys= [...Menu,"profile","availability","add-account" ]

export { MENUS, MenusKeys, MenusList };
