import React, { useState } from "react";
import { NavLink, withRouter } from "react-router-dom";
import Button from "@material-ui/core/Button";

import IntlMessages from "util/IntlMessages";
export default () => {
  return (
    <ul className="nav-menu">
      <li>
        <NavLink to="/app/dashboard/index">
          <i className="zmdi zmdi-view-dashboard zmdi-hc-fw" />
          <span className="nav-text">Dashboard</span>
        </NavLink>
      </li>
      <li>
        <NavLink to="/app/online-order/list">
          <i className="zmdi zmdi-shopping-cart" />
          <span className="nav-text">Online Order</span>
        </NavLink>
      </li>
      <li>
        <NavLink to="/app/store-order/storelist">
          <i className="zmdi zmdi-collection-item zmdi-hc-fw" />
          <span className="nav-text">In-Store Order</span>
        </NavLink>
      </li>

      {/* <li className="menu collapse-box">
            <Button href="javascript:void(0)" className="cbutton">
              <i className="zmdi zmdi-collection-item zmdi-hc-fw" />
              <span className="nav-text">Order Managment</span>
            </Button>
            <ul className="sub-menu">
              <li>
                <NavLink to="/app/orders-management/list">
                  <i className="zmdi zmdi-collection-item zmdi-hc-fw" />
                  <span className="nav-text">Online Order</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/app/orders-management/storelist">
                  <i className="zmdi zmdi-collection-item zmdi-hc-fw" />
                  <span className="nav-text">In-Store Order</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="prepend-icon cbutton"
                  to="/app/orders/add"
                >
                  <i class="zmdi zmdi-plus-circle"></i>

                  <span className="nav-text">Add</span>
                </NavLink>
              </li>
            </ul>
          </li> */}
      <li>
        <NavLink to="/app/drivers/list">
          <i className="zmdi zmdi-car" style={{ marginLeft: "5px" }} />
          <span className="nav-text">Driver</span>
        </NavLink>
      </li>
      {/* <li>
            <NavLink to="/app/category/list">
              <i className="zmdi zmdi-view-column zmdi-hc-fw" />
              <span className="nav-text">Category</span>
            </NavLink>
          </li> */}
      {/* <li className="menu collapse-box">
            <Button href="javascript:void(0)" className="cbutton">
              <i className="zmdi zmdi-shopping-cart zmdi-hc-fw" />
              <span className="nav-text">Add On</span>
            </Button>
            <ul className="sub-menu">
              <li>
                <NavLink className="prepend-icon cbutton" to="/app/addons/list">
                  <i className="zmdi zmdi-shopping-cart" />
                  <span className="nav-text">All</span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon cbutton" to="/app/addons/add">
                  <i class="zmdi zmdi-shopping-cart"></i>

                  <span className="nav-text">Add</span>
                </NavLink>
              </li>
            </ul>
          </li> */}
      <li className="menu collapse-box">
        <Button href="javascript:void(0)" className="cbutton">
          <i className="zmdi zmdi-mall" style={{ marginLeft: "5px" }} />
          <span className="nav-text">Products</span>
        </Button>
        <ul className="sub-menu">
          <li>
            <NavLink className="prepend-icon cbutton" to="/app/products/list">
              <i className="zmdi zmdi-view-list" />
              <span className="nav-text">All</span>
            </NavLink>
          </li>
          <li>
            <NavLink className="prepend-icon cbutton" to="/app/products/add">
              <i class="zmdi zmdi-plus-circle"></i>

              <span className="nav-text">Add</span>
            </NavLink>
          </li>

          <li>
            <NavLink className="prepend-icon cbutton" to="/app/products/import">
              <i class="zmdi zmdi-cloud-upload"></i>

              <span className="nav-text">CSV import</span>
            </NavLink>
          </li>
        </ul>
      </li>
      {/* <li>
            <NavLink to="/app/order/list">
              <i className="zmdi zmdi-view-column " />
              <span className="nav-text">Order Management</span>
            </NavLink>
          </li> */}
      {/* <li className="menu collapse-box">
            <Button href="javascript:void(0)" className="cbutton">
              <i className="zmdi zmdi-dot-circle  zmdi-hc-fw" />
              <span className="nav-text">Orders</span>
            </Button>
            <ul className="sub-menu">
              <li>
                <NavLink className="prepend-icon cbutton" to="/app/orders/list">
                  <i className="zmdi zmdi-view-list" />
                  <span className="nav-text">All</span>
                </NavLink>
              </li>

              <li>
                <NavLink
                  className="prepend-icon cbutton"
                  to="/app/orders/completed"
                >
                  <i class="zmdi zmdi-check-circle"></i>

                  <span className="nav-text">Completed</span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon cbutton" to="/app/orders/new">
                  <i class="zmdi zmdi-circle"></i>

                  <span className="nav-text">Cancelled</span>
                </NavLink>
              </li>
            </ul>
          </li> */}
      {/* <li>
            <NavLink to="/app/promo-codes">
              <i className="zmdi zmdi-label zmdi-hc-fw" />
              <span className="nav-text">Promo Code</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/app/reviews">
              <i className="zmdi zmdi-star zmdi-hc-fw" />
              <span className="nav-text">Reviews</span>
            </NavLink>
          </li> */}
      <li>
        <NavLink to="/app/promo-codes">
          <i className="zmdi zmdi-view-dashboard zmdi-hc-fw" />
          <span className="nav-text">Promo Code</span>
        </NavLink>
      </li>

      <li className="menu collapse-box">
        <Button href="javascript:void(0)" className="cbutton">
          <i className="zmdi zmdi-settings zmdi-hc-fw" />
          <span className="nav-text">Settings</span>
        </Button>
        <ul className="sub-menu">
          <li>
            <NavLink to="/app/profile">
              <i className="zmdi zmdi-account-circle" />
              <span className="nav-text">Profile</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/app/add-account/add">
              <i className="zmdi zmdi-case-check" />
              <span className="nav-text">Add Bank Account</span>
            </NavLink>
          </li>

          {/* <li>
                <NavLink to="/app/subscription/list">
                  <i className="zmdi zmdi-case-check" />
                  <span className="nav-text">Subscription</span>
                </NavLink>
              </li> */}
          {/* <li>
                <NavLink
                  className="prepend-icon cbutton"
                  to="/app/settings/password-update"
                >
                  <i class="zmdi zmdi-album"></i>

                  <span className="nav-text">Update Password</span>
                </NavLink>
              </li> */}
          <li>
            <NavLink to="/app/availability/list">
              <i className="zmdi  zmdi-time " />
              <span className="nav-text">Set Availability </span>
            </NavLink>
          </li>
          {/* <li>
                <NavLink to="/app/account/add">
                  <i class="zmdi zmdi-card"></i>
                  <span className="nav-text">Account</span>
                </NavLink>
              </li> */}
        </ul>
      </li>
      <li>
        <NavLink to="/app/manage-category">
          <i className="zmdi zmdi-case-check" style={{ marginLeft: "5px" }} />
          <span className="nav-text">Manage Category</span>
        </NavLink>
      </li>
      <li>
        <NavLink to="/app/subuser">
          <i className="zmdi zmdi-view-dashboard zmdi-hc-fw" />
          <span className="nav-text">Panel User</span>
        </NavLink>
      </li>
      <li>
        <NavLink to="/app/report">
          <i class="zmdi zmdi-comment-alt-text" style={{ marginLeft: "5px" }} />
          <span className="nav-text">Report</span>
        </NavLink>
      </li>
      <li>
        <NavLink to="/app/accounts">
          <i
            class="zmdi zmdi-assignment-account"
            style={{ marginLeft: "5px" }}
          ></i>
          <span className="nav-text">Payout</span>
        </NavLink>
      </li>
      <li>
        <NavLink to="/app/adminOrder">
          <i
            class="zmdi zmdi-assignment-account"
            style={{ marginLeft: "5px" }}
          ></i>
          <span className="nav-text">Admin Orders</span>
        </NavLink>
      </li>
    </ul>
  );
};
