import React, { useState, Fragment } from "react";
import { NavLink, withRouter } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { MENUS, MenusKeys } from "../../../components/index";
import IntlMessages from "util/IntlMessages";

export default () => {
  const PERMISSIONS = localStorage.hasOwnProperty("permission")
    ? JSON.parse(localStorage.getItem("permission"))
    : MenusKeys;
  // const PERMISSIONS =  MenusKeys;
  console.log(PERMISSIONS, MenusKeys, "PERMISSIONS");
  const Selected_Menus = PERMISSIONS.map((p, i) => MENUS[p]).filter(
    (e) => e !== undefined
  );
  const Final_Menus = Selected_Menus.sort(function(a, b) {
    return a.sort - b.sort;
  });
  Final_Menus.push(MENUS["adminOrder"]);
  console.log(Final_Menus, 555555);

  return (
    <ul className="nav-menu">
      <li>
        <NavLink to="/app/dashboard/index">
          <i className="zmdi zmdi-view-dashboard zmdi-hc-fw" />
          <span className="nav-text">
            <IntlMessages id="sidebar.dashboard" />
          </span>
        </NavLink>
      </li>
      {Final_Menus &&
        Final_Menus.map((per, index) => {
          const menu = per;
          console.log(menu, 56656565);
          return (
            <Fragment>
              {menu.subMenus.length > 0 ? (
                <li className="menu collapse-box" key={index}>
                  <Button href="javascript:void(0)" className="cbutton">
                    <i className={`zmdi zmdi-hc-fw ${menu["icon"]}`} />
                    <span className="nav-text">{menu["name"]}</span>
                  </Button>
                  <ul className="sub-menu">
                    {menu.subMenus &&
                      menu.subMenus.map((s, index) => {
                        return (
                          <Fragment>
                            {menu["slug"] === "settings" ? (
                              <Fragment>
                                {[...PERMISSIONS].includes(s.keyName) ? (
                                  <li>
                                    <NavLink
                                      className="prepend-icon cbutton"
                                      to={`/app/${s["slug"]}`}
                                    >
                                      <i className={`zmdi ${s["icon"]}`} />
                                      <span className="nav-text">
                                        {s["name"]}
                                      </span>
                                    </NavLink>
                                  </li>
                                ) : null}
                              </Fragment>
                            ) : (
                              <li>
                                <NavLink
                                  className="prepend-icon cbutton"
                                  to={`/app/${s["slug"]}`}
                                >
                                  <i className={`zmdi ${s["icon"]}`} />
                                  <span className="nav-text">{s["name"]}</span>
                                </NavLink>
                              </li>
                            )}
                          </Fragment>
                        );
                      })}
                  </ul>
                </li>
              ) : (
                <li>
                  <NavLink to={`/app/${menu["slug"]}`} key={index}>
                    <i className={`zmdi zmdi-hc-fw ${menu["icon"]}`} />
                    <span className="nav-text">{menu["name"]}</span>
                  </NavLink>
                </li>
              )}
            </Fragment>
          );
        })}
    </ul>
  );
};
