// Api BASE URLs

const env = require('./config/env')();

console.log("env", env)
export const API_URL = env.API_URL;

//export const API_URL = "https://api-dev.alcooly.com";
export const API_VERSION = "/api/v1";

// COMMON CONSTANT
export const API_TOKEN = localStorage.getItem("user_id");
export const EMAIL_REGEX = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
export const PASS_REGEX = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
export const Numeric = /^[0-9]+$/;
export const ALPHABET = /^[A-Za-z]+$/;
export const ALPHANUMERIC_REGEX = /[^a-z\d]/i;
export const PASS_VALIDATION_MSG =
  "password should be at least 8 characters, one uppercase, lowercase, special character (@,-,~,_), numeric value.";

// AUTH URL
export const API_LOGIN = "/store/storelogin";
export const API_FORGOT_PASSWORD = "/store/forgotPassword";
export const API_RESET_PASSWORD = "/store/changePasswords";

export const PERMISSIONS_ARRAY = [
  "dashboard",
  "online-order",
  "store-order",
  "products",
  "promo-codes",
  "profile",
  "add-account",
  "availability",
  "manage-category",
  "subuser",
  "report",
  "accounts",
  "drivers"
];

export const PERMISSIONS = PERMISSIONS_ARRAY.map((p) => {
  const name = p.replace("_", " ");
  return {
    [p]: name.charAt(0).toUpperCase() + name.slice(1),
  };
});

// DASHBOARD
export const DASHBOARD = "/store/getDashboardList";

// Stores
export const API_GET_STORES_URL = "/store/";
export const API_GET_STORES_BY_ID = "/store/getstorebyId/";
export const API_CHANGE_PRODUCT_STATUS = "/store/productStatusChange";
export const API_SET_AVAILABLITY = "/store/setAvailability";
export const API_GET_SETTINGS = "/store/getSettings";
export const API_EDIT_STORES = "/store/updatestore/";
export const API_ADD_STORE_BANKAC = "/store/addaccount/";
export const API_CHANGE_CATEGORY_STATUS = "/store/changeSettingStatus";
export const API_ADD_STORE_DOCS = "/store/addStoreDoc/";
export const API_GET_STORE_DOCS = "/store/getStoreDoc/";
export const API_UPDATED_STORE = "/store/updateStoreById";
export const API_GET_UNIT_SIZE = "/restaurant/getSizeUnit";

export const API_UPLOAD_STORE_ITEMS ="admin/importFile"

// Orders
export const API_GET_ADMIN_ORDER_BY_STORE_ID = "/store/getAdminOrdersByStoreId";
export const API_ORDERS_LIST = "/store/storeOrdersWithFilter";
export const API_ORDERS_BY_ID = "/store/orderdetails/";
export const API_ACCEPT_REJECT_ORDER = "/store/acceptDeclineOrder";
export const SEND_INSTRUCTIONS ="store/sendInstruction"
export const API_STORE_ORDERS_LIST = "/store/storeSoldItem";
export const API_TOP_SOLD_ITEM = "/store/topSoldItem";
export const API_GET_DRIVERS = "/store/avelableDrivers";
export const API_ASSIGN_DRIVER ="/store/driverAssign"
export const API_COMPLETE_ORDER = "store/completeOrder";
export const API_IN_STORE_ORDERS_LIST ="/store/storeOrder"
export const API_ORDER_RETURN ="store/recivedReturn";



//OTP verify
export const API_VERIFY_OTP = "/store/verifyOtp";
export const API_RESEND_OTP = "/store/resendOtp";

//Subscription package
export const API_GET_SUBSCRIPTION_LIST = "/store/subscriptions";

//Registration
export const API_ADD_CARD = "/payment/addCardInAuthorizedForStore/";
export const API_STOR_SUBSCRIBE = "/store/subscribe";
export const API_ADD_ACCOUNT = "/restaurant/addaccount";

//Varients
export const API_GET_VARIENTS = "/store/variants";

// Store's Categories
export const API_GET_STORES_CATEGORIES = "/storecategory/";
export const API_GET_STORES_CATEGORIE_BY_ID = "/storecategory/edit/";
export const API_ADD_STORES_CATEGORIE = "/storecategory/add/";
export const API_EDIT_STORES_CATEGORIE = "/storecategory/update";
export const API_ADD_STORES = "/store/register";
export const API_DELETE_STORES_CATEGORIE = "/storecategory/remove";

export const API_CATEGORY_ADD_URL = "/category/add";
export const API_CATEGORY_LIST_URL = "/category";
export const API_CATEGORY_GET_URL = "/category/addsubcategory";
export const API_CATEGORY_UPDATE_URL = "/category/updatesubcategory";
export const API_CATEGORY_DELETE_URL = "/category/remove";

export const API_GET_SUBCATEGORY = "/store/subCategories";
export const API_GET_SUBCATEGORY_BY_ID = "/category/edit/";
export const API_ADD_SUBCATEGORY = "/category/add";
export const API_EDIT_SUBCATEGORY = "/category/update";

export const API_GET_RESTAURANTS_URL = "/admin/getRestaurantWithFilter";
export const API_GET_RESTAURANTS_All = "/admin/restaurantlist";
export const API_RESTAURANTS_VIEW_URL = "/admin/restaurant/";
export const API_RESTAURANTS_DELETE_URL = "/admin/restaurant/remove";
export const API_RESTAURANTS_STATUS_URL = "/admin/restaurantstatus";

export const API_RESTAURANTS_ADD_URL = "/admin/restaurant/add";
export const API_GET_RESTAURANTS_PAYMENTS = "/admin/restaurantpaymentshistory/";
export const API_RESTAURANTS_PAYMENTS = "/admin/paytorestaurant/";

// Category
export const API_GET_CATEGORY_URL = "/store/categories";
export const API_GET_CATEGORY = "/category/edit/";
export const API_ADD_CATEGORY = "/category/add/";
export const API_EDIT_CATEGORY = "/category/update/";
export const API_DELETE_CATEGORY = "/category/remove/";

export const API_CUISINES_LIST_URL = "/admin/cuisines";
export const API_CUISINES_ADD_URL = "/admin/cuisines/add";
export const API_CUISINES_GET_URL = "/admin/cuisines/view";
export const API_CUISINES_UPDATE_URL = "/admin/cuisines/update";
export const API_CUISINES_DELETE_URL = "/admin/cuisines/remove";

export const API_GET_CATEGORIES = "/store/categories";

export const API_PROMOTIONS_LIST_URL = "/admin/promotions";
export const API_PROMOTIONS_ADD_URL = "/admin/promotions/add";
export const API_PROMOTIONS_GET_URL = "/admin/promotions/view";
export const API_PROMOTIONS_UPDATE_URL = "/admin/promotions/update";
export const API_PROMOTIONS_DELETE_URL = "/admin/promotions/remove";

// subscription
export const API_ADD_SUBSCRIPTION = "/admin/addSubscription";
export const API_GET_SUBSCRIPTIONS = "/admin/subscription";
export const API_GET_SUBSCRIPTION_BY_ID = "/admin/subscription/";
export const API_EDIT_SUBSCRIPTION = "/admin/editSubscription/";
export const API_DELETE_SUBSCRIPTION = "/admin/deleteSubscription/";

// Store's Items
export const API_GET_STORES_ITEMS = "/store/getAllStoreItem/";
export const API_ADD_PRODUCTS = "/restaurant/additem";
export const API_GET_PRODUCT_ITEM_BY_ID = "/store/getProductId/";
export const API_GET_PRODUCTS_BY_UPCID = "/store/upcId/";
export const API_EDIT_STORES_ITEM = "/store/editProduct/";
export const API_DELETE_PRODUCT = "/store/deleteProduct";


export const API_GET_STORES_ITEM_BY_ID = "/store/getItemById/";
export const API_EDIT_PRODUCT_ITEM = "/admin/updateproductbyAdmin/";

export const API_ADD_STORES_ITEM = "/store/addStoreItem/";
export const API_DELETE_STORES_ITEM = "/store/deleteStoreItem";
export const API_UPDATE_STORE_ITEM_STATUS = "/store/updateItemStatus";

// Chat
export const API_SEND_MESSAGE = "/admin/sendSupportOrderMessage";
export const API_GET_MESSAGE = "/admin/getSupportOrderMessate";
export const API_GET_CHAT_MESSAGE = "/store/chathistory";

// Reports
export const API_GET_REPORT_ITEMS = "/store/soldItem";
export const API_GET_ACCOUNTS_LIST ="/store/payOuts"

// Order
export const API_ADD_ORDERS = "/store/addOrder";
export const API_GET_ESTIMATE_COST = "/store/estimateOrder"
export const API_POST_UPDATE_ORDER = "/store/updateOrder"


// Product items
export const API_ADD_PRODUCT = "/admin/addProductsbyAdmin";
export const API_EDIT_PRODUCT = "/admin/updateproductbyAdmin";
// Drivers
export const API_GET_DRIVER_URL = "/store/allStoreDrivers";
export const API_GET_DRIVER = "store/driver/";
export const API_ADD_DRIVER = "/driver/registerbyadmin/";
export const API_EDIT_DRIVER = "/admin/driver/updateDriver/";
export const API_DELETE_DRIVER = "/admin/driver/deleteDriver/";
export const API_DRIVER_PAYMENTS =
  "/admin/driver/getAllDriverTransactionsById/";
export const API_STATUS_CHANGE_RIDER = "/store/driverStatusChange";
export const API_BLOCK_RIDER = "/admin/driver/blockUnblock/";

// Customers URLs
export const API_GET_CUSTOMERS_URL = "/admin/getUsersWithFilter/";
export const API_GET_CUSTOMER = "/admin/getUserById/";
export const API_ADD_CUSTOMER = "/admin/addUser/";
export const API_EDIT_CUSTOMER = "/admin/editUser/";
export const API_DELETE_CUSTOMER = "/admin/removeUser/";
export const API_UPLOAD_CUSTOMER = "/user/importCsvList/";

//Admin Setting URLs
export const API_GET_ALL_ADMINS = "store/getSubStore";
export const API_EDIT_ADMIN = "store/editSubStore";
export const API_ADD_ADMIN = "store/registerSubStore";
export const API_GET_ADMIN = "store/getSubStore/";
export const API_DELETE_PANEL_USER ="store/deleteSubStore"

// Content pages
export const API_GET_CONTENT_PAGES_URL = "/admin/contents/";
export const API_EDIT_CONTENT_PAGE = "/admin/contents/update";
export const API_GET_CONTENT_PAGE_BY_ID = "/admin/contents/edit/";

// Faq
export const API_GET_ALL_FAQ = "/admin/getAllFaqs/";
export const API_ADD_FAQ = "/admin/addFaq/";
export const API_EDIT_FAQ = "/admin/editFaq/";
export const API_GET_FAQ = "/admin/getFaqsById/";
export const API_DELETE_FAQ = "/admin/deleteFaq/";

///// Settings

//Mail Templates Url
export const API_GET_MAIL_TEMPLATES = "/adminSetting/getMailTitle/";
export const API_GET_MAIL_TEMPLATE = "/adminSetting/getMailTemplateById/";
export const API_ADD_MAIL_TEMPLATE = "/adminSetting/addMailTemplate/";
export const API_EDIT_MAIL_TEMPLATE = "/adminSetting/editMailTemplate/";

//Sms Templates Url
export const API_GET_SMS_TEMPLATES = "/adminSetting/getSmsTemplate/";
export const API_GET_SMS_TEMPLATE = "/adminSetting/getSmsTemplateById/";
export const API_ADD_SMS_TEMPLATE = "/adminSetting/addSmsTemplate/";
export const API_EDIT_SMS_TEMPLATE = "/adminSetting/editSmsTemplate/";

// Car Types Urls
// export const API_GET_ALL_CAR_TYPES = '/admin/cartypes/';
export const API_GET_ALL_CAR_TYPES = "main/adminCarTypes/getAllCarType";
export const API_GET_CAR_TYPE_BY_ID = "main/adminCarTypes/getCarTypeById/";
export const API_ADD_CAR_TYPE = "main/adminCarTypes/addCarType";
// export const API_GET_CAR_TYPE_BY_ID = '/admin/cartypes/edit/';
// export const API_EDIT_CAR_TYPE = '/admin/updatecartype/';
export const API_EDIT_CAR_TYPE = "main/adminCarTypes/editCarType";
export const API_GET_ALL_PRICING = "/admin/pslist/";
export const API_GET_PRICING_BY_ID = "/admin/psdetails/";
export const API_ADD_PRICING = "/admin/addps/";
export const API_EDIT_PRICING = "/admin/updateps/";
export const API_REMOVE_PRICING = "/admin/removeps/";

// Trips URLsapi/v1/admin/orderdetails/
export const API_GET_ORDERS = "/adminDashboard/allTripsWithFilter/";
export const API_GET_ORDER_BY_ID = "/admin/order/";

// Promo Code URLs
export const API_GET_ALL_PROMOCODE = "/promo/storePromo";
export const API_ADD_PROMOCODE = "/promo/addpromocodes";
export const API_EDIT_PROMOCODE = "/adminSetting/editPromocode/";
export const API_UPDATE_PROMOCODE_STATUS =
  "/adminSetting/updatePromocodeStatus/";
export const API_GET_PROMOCODE = "/adminSetting/getPromocodeDetails/";
export const API_DELETE_PROMOCODE = "/adminSetting/promocodeDelete/";

// Push Notification URLs
export const SEND_PUSH_NOTIFICATION = "admin/sendNotification";

// Settings
export const API_UPDATE_ADMIN_PASSWORD = "/store/changePassword/";
export const API_GET_BASIC_SETTINGS = "/adminSetting/getBasicsettinginfo";
export const API_UPDATE_BASIC_SETTINGS = "/adminSetting/basicAppSetting";
export const API_UPDATE_NOTIFICATION_SETTINGS =
  "/adminSetting/notificationSettingUpdate/";
export const API_UPDATE_SOCIAL_SETTINGS = "/adminSetting/basicSocialSetting";
export const API_INSTALLATION_SETTINGS = "/adminSetting/getConfigInfo";
export const API_UPDATE_SMS_INSTALLATION_SETTINGS =
  "/adminSetting/twilioUpdate";
export const API_UPDATE_PAYMENT_INSTALLATION_SETTINGS =
  "/adminSetting/PaymentConfigUpdate";
export const API_UPDATE_MAILGUN_INSTALLATION_SETTINGS =
  "/adminSetting/MailGunConfigUpdate";
export const API_UPDATE_ANDROID_INSTALLATION_SETTINGS =
  "/adminSetting/AndroidAppUrlUpdate";
export const API_UPDATE_IOS_INSTALLATION_SETTINGS =
  "/adminSetting/IOSAppURLUpdate";
export const API_GET_MAILTEMPLATE = "/adminSetting/getMailTitle";
export const API_UPDATE_MAILTEMPLATE = "/adminSetting/editMailTemplate";
export const API_GET_SMSTEMPALTE = "/adminSetting/getSmsTemplate";
export const API_UPDATE_SMSTEMPALTE = "/adminSetting/editSmsTemplate";


//report

export const API_GET_REPORT_ITEM_BY_ID = "/store/soldItem/";
export const API_GET_STORE_REPORT_ITEM_BY_ID = "/store/storeSoldItem/";
