const constants = require("../config/constants.js");
module.exports = () => {
  console.log("REACT_APP_ENVIRONMENT", process.env.REACT_APP_ENVIRONMENT)
  switch (process.env.REACT_APP_ENVIRONMENT) {
    case 'localhost':
      return {
        API_URL: constants.APIS.LOCALHOST.URL,
      };
    case 'test':
      return {
        //API_URL: constants.APIS.LOCALHOST.URL,
        API_URL: constants.APIS.TEST.URL,
      };
    case 'live':
      return {
        //API_URL: constants.APIS.LOCALHOST.URL,
        API_URL: constants.APIS.LIVE.URL,
      };
    case 'staging':
      return {
        //API_URL: constants.APIS.LOCALHOST.URL,
        API_URL: constants.APIS.STAGING.URL,
      }
  }
};
